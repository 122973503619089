import React from "react";
import { StatusBar } from "expo-status-bar";
import {
  Appearance,
  Dimensions,
  SafeAreaView,
  Text,
  TextInput,
  Pressable,
  View,
} from "react-native";
import Lists from "./pages/Lists";
import List from "./pages/List";

import Constants from "expo-constants";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { registerDevMenuItems } from "expo-dev-menu";

registerDevMenuItems([
  {
    name: "Toggle Dark Mode",
    callback: () => {
      App.instance.setState({ darkMode: !App.instance.state.darkMode });
    },
  },
]);

export default class App extends React.Component<
  {},
  {
    apiRoot: string | null;
    apiToken: string | null;

    newApiRoot: string | null;
    newApiToken: string | null;

    darkMode: boolean;
    page: "lists" | "list" | "item";
    pageProps: any;
    history: {
      page: "lists" | "list" | "item";
      pageProps: any;
    }[];
  }
> {
  constructor(props: {}) {
    super(props);
    this.state = {
      apiRoot: null,
      apiToken: null,

      newApiRoot: Constants.expoConfig?.extra?.apiRoot ?? null,
      newApiToken: Constants.expoConfig?.extra?.apiToken ?? null,

      darkMode: Appearance.getColorScheme() === "dark",
      page: "lists",
      pageProps: {},
      history: [],
    };

    App.instance = this;

    this.loadToken();
  }

  async loadToken() {
    const apiToken = await AsyncStorage.getItem("apiToken");
    if (apiToken) {
      this.setState({ apiToken });
    }

    const apiRoot = await AsyncStorage.getItem("apiRoot");
    if (apiRoot) {
      this.setState({ apiRoot });
    }
  }

  static instance: App;

  navigate(page: "lists" | "list" | "item", pageProps: any) {
    this.setState({
      page,
      pageProps,
      history: [
        ...this.state.history,
        { page: this.state.page, pageProps: this.state.pageProps },
      ],
    });
  }

  goBack(string?: "login") {
    if (string === "login") {
      this.setState({
        apiToken: null,
        newApiToken: null,
        page: "lists",
        pageProps: {},
        history: [],
      });
      AsyncStorage.removeItem("apiToken");
      return;
    }

    const history = [...this.state.history];
    if (history.length === 0) return;
    const { page, pageProps } = history.pop() ?? {};
    if (!page || !pageProps) return;
    this.setState({ page, pageProps, history });
  }

  render() {
    if (!this.state.apiToken || !this.state.apiRoot)
      return (
        <SafeAreaView
          style={{
            backgroundColor: this.state.darkMode ? "#000" : "#fff",
            width: "100%",
            height: "100%",
          }}
        >
          <StatusBar
            style={this.state.darkMode ? "light" : "dark"}
            backgroundColor={this.state.darkMode ? "#000" : "#fff"}
            hidden={false}
            translucent={false}
          />
          <View
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: 30,
                fontWeight: "bold",
                color: this.state.darkMode ? "#fff" : "#000",
                marginBottom: 20,
              }}
            >
              Login
            </Text>
            <Text
              style={{
                color: this.state.darkMode ? "#fff" : "#000",
              }}
            >
              API Root:
            </Text>
            <TextInput
              style={{
                width: 200,
                height: 40,
                borderColor: "gray",
                borderWidth: 1,
                borderRadius: 5,
                padding: 5,
                margin: 5,
                color: this.state.darkMode ? "#fff" : "#000",
              }}
              onChangeText={(text) => {
                this.setState({ newApiRoot: text });
              }}
              defaultValue={""}
              value={this.state.newApiRoot ?? ""}
              textContentType="username"
              autoComplete="username"
              importantForAutofill="yes"
            />
            <Text
              style={{
                color: this.state.darkMode ? "#fff" : "#000",
              }}
            >
              API Token:
            </Text>
            <TextInput
              style={{
                width: 200,
                height: 40,
                borderColor: "gray",
                borderWidth: 1,
                borderRadius: 5,
                padding: 5,
                margin: 5,
                color: this.state.darkMode ? "#fff" : "#000",
              }}
              onChangeText={(text) => {
                this.setState({ newApiToken: text });
              }}
              defaultValue={""}
              value={this.state.newApiToken ?? ""}
              textContentType="password"
              autoComplete="password"
              importantForAutofill="yes"
              secureTextEntry={true}
            />
            <Pressable
              onPress={async () => {
                if (!this.state.newApiRoot || !this.state.newApiToken) return;
                await AsyncStorage.setItem(
                  "apiRoot",
                  this.state.newApiRoot ?? ""
                );
                await AsyncStorage.setItem(
                  "apiToken",
                  this.state.newApiToken ?? ""
                );
                await this.loadToken();
              }}
              style={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",

                marginTop: 10,

                backgroundColor:
                  !this.state.newApiRoot || !this.state.newApiToken
                    ? "#aaa"
                    : "#037ffc",
                borderRadius: 10,
                padding: 10,
              }}
            >
              <Text
                style={{
                  color: "#fff",
                }}
              >
                Login
              </Text>
            </Pressable>
          </View>
        </SafeAreaView>
      );

    return (
      <SafeAreaView
        style={{
          backgroundColor: this.state.darkMode ? "#000" : "#fff",
          width: "100%",
          height: "100%",
        }}
      >
        <StatusBar
          style={this.state.darkMode ? "light" : "dark"}
          backgroundColor={this.state.darkMode ? "#000" : "#fff"}
          hidden={false}
          translucent={false}
        />
        {this.state.history.length > 0 ? (
          <View
            style={{
              height: 30,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: 10,
              marginLeft: 20,
            }}
          >
            <Pressable
              onPress={() => {
                this.goBack();
              }}
              style={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",

                marginTop: 10,

                backgroundColor: "#037ffc",
                borderRadius: 10,
                padding: 10,
              }}
            >
              <Text
                style={{
                  color: "#fff",
                }}
              >
                Zurück
              </Text>
            </Pressable>
          </View>
        ) : null}

        <View
          style={{
            width: "100%",
            height:
              this.state.history.length > 0
                ? Dimensions.get("window").height - 50
                : "100%",
          }}
        >
          {this.getPage()}
        </View>
      </SafeAreaView>
    );
  }

  getPage() {
    if (this.state.page === "lists") {
      return (
        <Lists
          apiRoot={this.state.apiRoot as string}
          apiToken={this.state.apiToken as string}
          darkMode={this.state.darkMode}
          navigate={this.navigate.bind(this)}
          goBack={this.goBack.bind(this)}
        />
      );
    } else if (this.state.page === "list") {
      return (
        <List
          apiRoot={this.state.apiRoot as string}
          apiToken={this.state.apiToken as string}
          darkMode={this.state.darkMode}
          navigate={this.navigate.bind(this)}
          goBack={this.goBack.bind(this)}
          pageProps={this.state.pageProps}
        />
      );
    }
  }
}
