import React from "react";
import E from "../types";
import { View, Pressable, Text } from "react-native";
import { getColorForCategory } from "../colors";

class ListItem extends React.Component<
  {
    darkMode: boolean;
    categories: E.Kategorie[];
    item: E.Item;
    onPress: () => void;
    onLongPress?: () => void;
    onDelete?: () => void;
  },
  {
    confirmDelete: boolean;
  }
> {
  constructor(props: {
    darkMode: boolean;
    categories: E.Kategorie[];
    item: E.Item;
    onPress: () => void;
    onLongPress?: () => void;
    onDelete?: () => void;
  }) {
    super(props);
    this.state = {
      confirmDelete: false,
    };
  }

  private confirmDeleteTimeout: NodeJS.Timeout | null = null;

  deleteItem() {
    if (this.state.confirmDelete) {
      this.props.onDelete?.();
    }
    this.setState({ confirmDelete: true });
    clearTimeout(this.confirmDeleteTimeout!);
    this.confirmDeleteTimeout = setTimeout(() => {
      this.setState({ confirmDelete: false });
    }, 3000);
  }

  render() {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 5,
          margin: 5,

          backgroundColor: this.props.darkMode ? "#333" : "#ddd",
          borderRadius: 10,
        }}
      >
        <Pressable
          onPress={() => {
            this.props.onPress();
          }}
          onLongPress={() => {
            if (this.props.onLongPress) {
              this.props.onLongPress();
            } else {
              this.props.onPress();
            }
          }}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: 20,
              width: this.props.onDelete ? "75%" : "100%",
              backgroundColor: getColorForCategory(
                this.props.categories.find(
                  (c) => c.id === this.props.item.category_id
                )!.name
              ).backgroundColor,
              color: getColorForCategory(
                this.props.categories.find(
                  (c) => c.id === this.props.item.category_id
                )!.name
              ).color,
              borderRadius: 10,
              padding: 5,
            }}
          >
            {this.props.item.name}
          </Text>
          {this.props.onDelete ? (
            <Pressable
              onPress={() => {
                if (this.props.onDelete) {
                  this.deleteItem();
                }
              }}
              style={{
                position: "absolute",
                right: 0,
                width: 30,
                height: 30,
                backgroundColor: "#f00",
                justifyContent: "center",
                borderRadius: 10,
              }}
            >
              <Text
                style={{
                  color: "#fff",
                  textAlign: "center",
                  fontSize: 20,
                }}
              >
                {this.state.confirmDelete ? "✔️" : "🗑️"}
              </Text>
            </Pressable>
          ) : null}
        </Pressable>
      </View>
    );
  }
}

export default ListItem;
