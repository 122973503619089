import React from "react";
import E from "../types";
import {
  Dimensions,
  ScrollView,
  Text,
  Pressable,
  View,
  Modal,
  TextInput,
} from "react-native";
import Selector from "../components/Selector";
import ListItem from "../components/ListItem";
import AddItemModal from "../components/modals/AddItemModal";
import RenameCategoryModal from "../components/modals/RenameCategoryModal";
import AddCategoryModal from "../components/modals/AddCategoryModal";
import EditItemModal from "../components/modals/EditItemModal";
import EditItemOnListModal from "../components/modals/EditItemOnListModal";

export default class List extends React.Component<
  {
    apiRoot: string;
    apiToken: string;
    darkMode: boolean;
    navigate: (page: "lists" | "list" | "item", pageProps: any) => void;
    goBack: () => void;
    pageProps: {
      list: E.Liste;
    };
  },
  {
    categories: E.Kategorie[];
    items: E.Item[];
    itemsOnList: E.ItemOnList[];

    loaded: boolean;

    itemsCategorySelected: Omit<E.Kategorie, "einkaufsliste_id"> | null;

    addCategoryModal: boolean;
    addItemModal: boolean;

    renameCategoryModal: boolean;
    renameCategory: Omit<E.Kategorie, "einkaufsliste_id"> | null;

    editItemModal: boolean;
    editItem: E.Item | null;

    editItemOnListModal: boolean;
    editItemOnList: E.ItemOnList | null;

    search: string;
  }
> {
  constructor(props: {
    apiRoot: string;
    apiToken: string;
    darkMode: boolean;
    navigate: (page: "lists" | "list" | "item", pageProps: any) => void;
    goBack: () => void;
    pageProps: {
      list: E.Liste;
    };
  }) {
    super(props);
    this.state = {
      categories: [],
      items: [],
      itemsOnList: [],
      loaded: false,

      itemsCategorySelected: null,

      addCategoryModal: false,
      addItemModal: false,

      renameCategoryModal: false,
      renameCategory: null,

      editItemModal: false,
      editItem: null,

      editItemOnListModal: false,
      editItemOnList: null,

      search: "",
    };
  }

  componentDidMount() {
    Promise.all([
      this.fetchCategories(),
      this.fetchItems(),
      this.fetchItemsOnList(),
    ]).then(() => {
      this.setState({
        loaded: true,
      });
    });
  }

  async fetchCategories() {
    await fetch(
      `${this.props.apiRoot}/lists/${this.props.pageProps.list.id}/categories`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.props.apiToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((categories: E.Kategorie[]) => {
        this.setState({
          categories,
        });
      });
  }

  async fetchItems() {
    await fetch(
      `${this.props.apiRoot}/lists/${this.props.pageProps.list.id}/items`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.props.apiToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((items: E.Item[]) => {
        this.setState({
          items,
        });
      });
  }

  async fetchItemsOnList() {
    await fetch(
      `${this.props.apiRoot}/lists/${this.props.pageProps.list.id}/items_on_list`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.props.apiToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((itemsOnList: E.ItemOnList[]) => {
        this.setState({
          itemsOnList,
        });
      });
  }

  render() {
    if (!this.state.loaded)
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: this.props.darkMode ? "#fff" : "#000",
              fontSize: 40,
            }}
          >
            Loading...
          </Text>
        </View>
      );

    const { categories, items, itemsOnList } = { ...this.state };

    categories.sort((a, b) => a.name.localeCompare(b.name));
    items.sort((a, b) => a.name.localeCompare(b.name));
    itemsOnList.sort((a, b) => {
      const name = items
        .find((item) => item.id === a.item_id)!
        .name.localeCompare(items.find((item) => item.id === b.item_id)!.name);
      const category = categories
        .find(
          (category) =>
            category.id ===
            items.find((item) => item.id === a.item_id)!.category_id
        )!
        .name.localeCompare(
          categories.find(
            (category) =>
              category.id ===
              items.find((item) => item.id === b.item_id)!.category_id
          )!.name
        );
      return category === 0 ? name : category;
    });

    const filteredItems = items.filter((item) =>
      item.name.toLowerCase().includes(this.state.search.toLowerCase())
    );

    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          width: Dimensions.get("window").width,
          height: Dimensions.get("window").height - 50,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            width: Dimensions.get("window").width / 2 - 20,
            height: Dimensions.get("window").height - 50 - 20,
            padding: 10,
            margin: 10,

            backgroundColor: this.props.darkMode ? "#222" : "#eee",
            borderRadius: 10,
          }}
        >
          <ScrollView>
            {itemsOnList.map((itemOnList) => {
              const item = items.find((i) => i.id === itemOnList.item_id)!;

              return (
                <ListItem
                  key={itemOnList.id}
                  darkMode={this.props.darkMode}
                  categories={categories}
                  item={{
                    id: item.id,
                    name: `${itemOnList.amount}x ${item.name} ${
                      itemOnList.additional_info
                        ? `(${itemOnList.additional_info})`
                        : ""
                    }`,
                    category_id: item.category_id,
                    einkaufsliste_id: item.einkaufsliste_id,
                  }}
                  onPress={() => {
                    this.setState({
                      editItemOnListModal: true,
                      editItemOnList: itemOnList,
                    });
                  }}
                  onDelete={() => {
                    fetch(
                      `${this.props.apiRoot}/lists/${this.props.pageProps.list.id}/items_on_list/${itemOnList.id}`,
                      {
                        method: "DELETE",
                        headers: {
                          Authorization: `Bearer ${this.props.apiToken}`,
                        },
                      }
                    )
                      .then((res) => res.json())
                      .then((res) => {
                        this.setState({
                          itemsOnList: this.state.itemsOnList.filter(
                            (i) => i.id !== res.id
                          ),
                        });
                      });
                  }}
                ></ListItem>
              );
            })}
          </ScrollView>
        </View>
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Selector
              options={this.state.categories.map((c) => ({
                id: c.id,
                name: c.name,
              }))}
              darkMode={this.props.darkMode}
              onChange={(value) => {
                this.setState({
                  itemsCategorySelected: value,
                });
              }}
              addOption={true}
              onAddOption={() =>
                this.setState({
                  addCategoryModal: true,
                })
              }
              renameOption={true}
              onRenameOption={(option) => {
                this.setState({
                  renameCategoryModal: true,
                  renameCategory: option,
                });
              }}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextInput
              style={{
                width: Dimensions.get("window").width / 2 - 20,
                height: 50,
                padding: 10,
                margin: 10,
                backgroundColor: this.props.darkMode ? "#222" : "#eee",
                borderRadius: 10,
                color: this.props.darkMode ? "#fff" : "#000",
              }}
              placeholder="Search..."
              placeholderTextColor={this.props.darkMode ? "#aaa" : "#666"}
              value={this.state.search}
              onChangeText={(text) => {
                this.setState({
                  search: text,
                });
              }}
            />
          </View>
          <View
            style={{
              width: Dimensions.get("window").width / 2 - 20,
              height: Dimensions.get("window").height - 194.5,
              padding: 10,
              margin: 10,

              backgroundColor: this.props.darkMode ? "#222" : "#eee",
              borderRadius: 10,
            }}
          >
            <ScrollView>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 5,
                  margin: 5,

                  backgroundColor: this.props.darkMode ? "#333" : "#ddd",
                  borderRadius: 10,
                }}
              >
                <Pressable
                  onPress={() => {
                    this.setState({
                      addItemModal: true,
                    });
                  }}
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      color: this.props.darkMode ? "#fff" : "#000",
                      fontSize: 20,
                    }}
                  >
                    --- Neu ---
                  </Text>
                </Pressable>
              </View>
              {filteredItems
                .filter(
                  (item) =>
                    this.state.itemsCategorySelected === null ||
                    this.state.itemsCategorySelected.id === item.category_id
                )
                .map((item) => (
                  <ListItem
                    item={item}
                    categories={categories}
                    key={item.id}
                    darkMode={this.props.darkMode}
                    onPress={() => {
                      fetch(
                        `${this.props.apiRoot}/lists/${this.props.pageProps.list.id}/items_on_list`,
                        {
                          method: "POST",
                          headers: {
                            Authorization: `Bearer ${this.props.apiToken}`,
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            item_id: item.id,
                            amount: 1,
                            additional_info: "",
                          }),
                        }
                      )
                        .then((res) => res.json())
                        .then((res) => {
                          this.setState({
                            itemsOnList: [...this.state.itemsOnList, res],
                          });
                        });
                    }}
                    onDelete={() => {
                      fetch(
                        `${this.props.apiRoot}/lists/${this.props.pageProps.list.id}/items/${item.id}`,
                        {
                          method: "DELETE",
                          headers: {
                            Authorization: `Bearer ${this.props.apiToken}`,
                          },
                        }
                      )
                        .then((res) => res.json())
                        .then((res) => {
                          this.setState({
                            items: this.state.items.filter(
                              (i) => i.id !== res.id
                            ),
                            itemsOnList: this.state.itemsOnList.filter(
                              (i) => i.item_id !== res.id
                            ),
                          });
                        });
                    }}
                    onLongPress={() => {
                      this.setState({
                        editItemModal: true,
                        editItem: item,
                      });
                    }}
                  />
                ))}
            </ScrollView>
          </View>
        </View>
        <Modal
          visible={this.state.addCategoryModal}
          onRequestClose={() => {
            this.setState({
              addCategoryModal: false,
            });
          }}
          transparent={true}
        >
          <AddCategoryModal
            darkMode={this.props.darkMode}
            apiRoot={this.props.apiRoot}
            apiToken={this.props.apiToken}
            list={this.props.pageProps.list.id}
            close={(category) => {
              if (category)
                this.setState({
                  addCategoryModal: false,
                  categories: [...this.state.categories, category],
                });
              else
                this.setState({
                  addCategoryModal: false,
                });
            }}
          />
        </Modal>
        <Modal
          visible={this.state.renameCategoryModal}
          onRequestClose={() => {
            this.setState({
              addCategoryModal: false,
            });
          }}
          transparent={true}
        >
          <RenameCategoryModal
            category={
              {
                ...this.state.renameCategory,
                einkaufsliste_id: this.props.pageProps.list.id,
              } as E.Kategorie
            }
            darkMode={this.props.darkMode}
            apiRoot={this.props.apiRoot}
            apiToken={this.props.apiToken}
            close={(category) => {
              if (category)
                this.setState({
                  renameCategoryModal: false,
                  categories: this.state.categories.map((c) =>
                    c.id === category.id ? category : c
                  ),
                });
              else if (category === null)
                this.setState({
                  renameCategoryModal: false,
                  categories: this.state.categories.filter(
                    (c) => c.id !== this.state.renameCategory?.id
                  ),
                });
              else
                this.setState({
                  renameCategoryModal: false,
                });
            }}
          />
        </Modal>
        <Modal
          visible={this.state.addItemModal}
          onRequestClose={() => {
            this.setState({
              addItemModal: false,
            });
          }}
          transparent={true}
        >
          <AddItemModal
            apiRoot={this.props.apiRoot}
            apiToken={this.props.apiToken}
            categories={this.state.categories}
            close={(item) => {
              if (item)
                this.setState({
                  addItemModal: false,
                  items: [...this.state.items, item],
                });
              else
                this.setState({
                  addItemModal: false,
                });
            }}
            list={this.props.pageProps.list.id}
            darkMode={this.props.darkMode}
          />
        </Modal>
        <Modal
          visible={this.state.editItemModal}
          onRequestClose={() => {
            this.setState({
              editItemModal: false,
            });
          }}
          transparent={true}
        >
          <EditItemModal
            item={this.state.editItem as E.Item}
            apiRoot={this.props.apiRoot}
            apiToken={this.props.apiToken}
            close={(item) => {
              if (item)
                this.setState({
                  editItemModal: false,
                  items: this.state.items.map((i) =>
                    i.id === item.id ? item : i
                  ),
                });
              else
                this.setState({
                  editItemModal: false,
                });
            }}
            categories={this.state.categories}
            darkMode={this.props.darkMode}
          />
        </Modal>
        <Modal
          visible={this.state.editItemOnListModal}
          onRequestClose={() => {
            this.setState({
              editItemOnListModal: false,
            });
          }}
          transparent={true}
        >
          <EditItemOnListModal
            itemOnList={this.state.editItemOnList as E.ItemOnList}
            item={
              this.state.items.find(
                (i) => i.id === this.state.editItemOnList?.item_id
              ) as E.Item
            }
            apiRoot={this.props.apiRoot}
            apiToken={this.props.apiToken}
            close={(item) => {
              this.setState({
                editItemOnListModal: false,
                itemsOnList: this.state.itemsOnList.map((i) => {
                  if (i.id === item.id) {
                    return item;
                  }
                  return i;
                }),
              });
            }}
            darkMode={this.props.darkMode}
          />
        </Modal>
      </View>
    );
  }
}
