export function getColorForCategory(category: string) {
  // Generate a color from the category name and a text color based on the luminance of the background
  const hash = category.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
  const color = "#" + ("000000" + Math.abs(hash).toString(16)).slice(-6);
  const luminance =
    0.2126 * parseInt(color.substring(1, 3), 16) +
    0.7152 * parseInt(color.substring(3, 5), 16) +
    0.0722 * parseInt(color.substring(5, 7), 16);
  const textColor = luminance > 128 ? "#000000" : "#ffffff";
  return {
    backgroundColor: color,
    color: textColor,
  };
}

export function getTextContrastColor(color: string) {
  const luminance =
    0.2126 * parseInt(color.substring(1, 3), 16) +
    0.7152 * parseInt(color.substring(3, 5), 16) +
    0.0722 * parseInt(color.substring(5, 7), 16);
  const textColor = luminance > 128 ? "#000000" : "#ffffff";
  return textColor;
}

export function darkenColor(color: string, amount: number) {
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);
  const hsl = rgbToHsl(r, g, b);
  const newHsl = [hsl[0], hsl[1], hsl[2] - amount];
  const newRgb = hslToRgb(newHsl[0], newHsl[1], newHsl[2]);
  return (
    "#" +
    ("00" + newRgb[0].toString(16)).slice(-2) +
    ("00" + newRgb[1].toString(16)).slice(-2) +
    ("00" + newRgb[2].toString(16)).slice(-2)
  );
}

export function lightenColor(color: string, amount: number) {
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);
  const hsl = rgbToHsl(r, g, b);
  const newHsl = [hsl[0], hsl[1], hsl[2] + amount];
  const newRgb = hslToRgb(newHsl[0], newHsl[1], newHsl[2]);
  return (
    "#" +
    ("00" + newRgb[0].toString(16)).slice(-2) +
    ("00" + newRgb[1].toString(16)).slice(-2) +
    ("00" + newRgb[2].toString(16)).slice(-2)
  );
}

export function rgbToHsl(r: number, g: number, b: number) {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h = 0,
    s = 0,
    l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = ((g - b) / d + (g < b ? 6 : 0)) / 6;
        break;
      case g:
        h = ((b - r) / d + 2) / 6;
        break;
      case b:
        h = ((r - g) / d + 4) / 6;
        break;
    }
  }

  return [h, s, l];
}

export function hslToRgb(h: number, s: number, l: number) {
  let r: number, g: number, b: number;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) {
        t += 1;
      } else if (t > 1) {
        t -= 1;
      }

      if (t < 1 / 6) {
        return p + (q - p) * 6 * t;
      }

      if (t < 1 / 2) {
        return q;
      }

      if (t < 2 / 3) {
        return p + (q - p) * (2 / 3 - t) * 6;
      }

      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [
    Math.round(r * 255),
    Math.round(g * 255),
    Math.round(b * 255),
  ] as const;
}
