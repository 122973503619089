import React from "react";
import type E from "../../types";
import { View, Text, TextInput, Pressable } from "react-native";
import Selector from "../Selector";

class EditItemModal extends React.Component<
  {
    item: E.Item;
    apiRoot: string;
    apiToken: string;
    close: (item: E.Item | null) => void;
    darkMode: boolean;
    categories: E.Kategorie[];
  },
  {
    name: string;
    category_id: string;
  }
> {
  constructor(props: {
    item: E.Item;
    apiRoot: string;
    apiToken: string;
    close: (item: E.Item | null) => void;
    darkMode: boolean;
    categories: E.Kategorie[];
  }) {
    super(props);
    this.state = {
      name: props.item.name,
      category_id: props.item.category_id,
    };
  }

  editItem() {
    if (this.state.name === "") return;
    if (this.state.category_id === "") return;

    fetch(
      `${this.props.apiRoot}/lists/${this.props.item.einkaufsliste_id}/items/${this.props.item.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.apiToken,
        },
        body: JSON.stringify({
          name: this.state.name,
          category_id: this.state.category_id,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.props.close(res);
      });
  }

  render() {
    return (
      <>
        <Pressable
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          onPress={() => {
            this.props.close(null);
          }}
        ></Pressable>
        <View
          style={{
            backgroundColor: "#000",
            padding: 10,
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",

            position: "absolute",
            top: "10%",
            left: "10%",

            width: "80%",
            height: "30%",
          }}
        >
          <Text
            style={{
              color: "#fff",
              fontSize: 20,
            }}
          >
            Bearbeite {this.props.item.name}
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: "#fff",
              }}
            >
              Name:{" "}
            </Text>
            <TextInput
              placeholder="Name"
              keyboardType="default"
              defaultValue={this.state.name}
              onChange={(e) => {
                this.setState({ name: e.nativeEvent.text });
              }}
              style={{
                width: "50%",
                color: "#fff",
                fontSize: 20,

                textAlign: "center",

                padding: 5,
                margin: 5,
                borderRadius: 10,

                borderColor: "#fff",
                borderStyle: "solid",
                borderWidth: 1,
              }}
            />
          </View>
          <Selector
            darkMode={this.props.darkMode}
            onChange={(e) => {
              if (e) this.setState({ category_id: e.id });
            }}
            options={this.props.categories}
            noUnset={true}
            default={this.props.categories.find(
              (c) => c.id === this.state.category_id
            )}
          />
          <Pressable
            onPress={() => {
              this.editItem();
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontSize: 20,

                backgroundColor: "#f00",
                borderRadius: 10,
                padding: 5,
              }}
            >
              Speichern
            </Text>
          </Pressable>
        </View>
      </>
    );
  }
}

export default EditItemModal;
