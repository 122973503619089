import React from "react";
import E from "../types";
import {
  Dimensions,
  Modal,
  ScrollView,
  Text,
  TextInput,
  Pressable,
  View,
} from "react-native";

export default class Lists extends React.Component<
  {
    apiRoot: string;
    apiToken: string;
    darkMode: boolean;
    navigate: (page: "lists" | "list" | "item", pageProps: any) => void;
    goBack: (string?: "login") => void;
  },
  {
    loaded: boolean;
    lists: E.Liste[];
    confirmDelete: E.Liste | null;
    addListModal: boolean;
    newListName: string;
  }
> {
  constructor(props: {
    apiRoot: string;
    apiToken: string;
    darkMode: boolean;
    navigate: (page: "lists" | "list" | "item", pageProps: any) => void;
    goBack: () => void;
  }) {
    super(props);
    this.state = {
      loaded: false,
      lists: [],
      confirmDelete: null,
      addListModal: false,
      newListName: "",
    };
  }

  componentDidMount() {
    this.fetchLists();
  }

  async fetchLists() {
    await fetch(`${this.props.apiRoot}/lists`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.props.apiToken}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          this.props.goBack("login");
        }

        return res.json();
      })
      .then((lists) => {
        this.setState({ lists, loaded: true });
        console.log(lists);
      })
      .catch((err) => {
        this.props.goBack("login");
      });
  }

  async deleteList(list: E.Liste) {
    return fetch(`${this.props.apiRoot}/lists/${list.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${this.props.apiToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          lists: this.state.lists.filter((l) => l.id !== res.id),
        });
      });
  }

  private confirmDeleteTimeout: NodeJS.Timeout | null = null;
  confirmDelete(list: E.Liste) {
    if (this.state.confirmDelete && this.state.confirmDelete.id === list.id) {
      this.deleteList(list);
      this.setState({ confirmDelete: null });
    } else {
      if (this.confirmDeleteTimeout) clearTimeout(this.confirmDeleteTimeout);
      this.setState({ confirmDelete: list });
      this.confirmDeleteTimeout = setTimeout(() => {
        this.setState({ confirmDelete: null });
      }, 3000);
    }
  }

  async addList() {
    await fetch(`${this.props.apiRoot}/lists`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.props.apiToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: this.state.newListName }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          newListName: "",
          addListModal: false,
          lists: [...this.state.lists, res],
        });
      });
  }

  render() {
    if (!this.state.loaded)
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: this.props.darkMode ? "#fff" : "#000",
              fontSize: 40,
            }}
          >
            Loading...
          </Text>
        </View>
      );

    return (
      <ScrollView
        style={{
          height: Dimensions.get("window").height - 50,
        }}
      >
        <View
          style={{
            borderRadius: 10,
            padding: 20,
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          <Text
            style={{
              fontSize: 30,
              color: this.props.darkMode ? "#fff" : "#000",
            }}
          >
            Listen
          </Text>
        </View>

        {this.state.lists
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          })
          .map((list) => (
            <Pressable
              key={list.id}
              style={{
                margin: 20,
                borderRadius: 10,
                backgroundColor: this.props.darkMode ? "#333" : "#eee",
                padding: 20,
                marginBottom: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onPress={() => {
                this.props.navigate("list", { list });
              }}
            >
              <Text
                style={{
                  fontSize: 20,
                  color: this.props.darkMode ? "#fff" : "#000",
                }}
              >
                {list.name}
              </Text>
              <View>
                {/* Delete Button */}
                <Pressable
                  onPress={() => {
                    // confirm
                    this.confirmDelete(list);
                  }}
                  style={{
                    backgroundColor: "#f00",
                    borderRadius: 10,
                    padding: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      color: this.props.darkMode ? "#fff" : "#000",
                    }}
                  >
                    {this.state.confirmDelete &&
                    this.state.confirmDelete.id === list.id
                      ? "✔️"
                      : "🗑️"}
                  </Text>
                </Pressable>
              </View>
            </Pressable>
          ))}
        {/* Add List Button */}
        <Pressable
          style={{
            margin: 20,
            borderRadius: 10,
            backgroundColor: "#8f8",
            padding: 20,
            marginBottom: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onPress={() => {
            this.setState({ addListModal: true, newListName: "" });
          }}
        >
          <Text
            style={{
              fontSize: 20,
              color: "#000",
            }}
          >
            + Liste hinzufügen
          </Text>
        </Pressable>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.addListModal}
          onRequestClose={() => {
            this.setState({ addListModal: false, newListName: "" });
          }}
        >
          <View
            style={{
              backgroundColor: this.props.darkMode ? "#000" : "#fff",
              height: "100%",
            }}
          >
            <View
              style={{
                borderRadius: 10,
                padding: 20,
                marginBottom: 0,
                paddingBottom: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 20,
                  color: this.props.darkMode ? "#fff" : "#000",
                  backgroundColor: "#f00",
                  padding: 5,
                  borderRadius: 5,

                  marginBottom: 10,
                  width: 30,
                  textAlign: "center",
                }}
                onPress={() => {
                  this.setState({ addListModal: false });
                }}
              >
                X
              </Text>
            </View>
            <View
              style={{
                borderRadius: 10,
                padding: 20,
                marginBottom: 0,
                paddingBottom: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  fontSize: 30,
                  color: this.props.darkMode ? "#fff" : "#000",
                }}
              >
                Liste hinzufügen
              </Text>
              <TextInput
                style={{
                  fontSize: 20,
                  color: this.props.darkMode ? "#fff" : "#000",
                  backgroundColor: this.props.darkMode ? "#333" : "#eee",
                  padding: 5,
                  borderRadius: 5,

                  marginBottom: 10,
                  width: "100%",
                  textAlign: "left",
                }}
                placeholder="Name"
                onChangeText={(text) => {
                  this.setState({ newListName: text });
                }}
                value={this.state.newListName}
              />
              <Text
                style={{
                  fontSize: 20,
                  color: this.props.darkMode ? "#fff" : "#000",
                  backgroundColor: "#0f0",
                  padding: 5,
                  borderRadius: 5,

                  marginBottom: 10,
                  width: 30,
                  textAlign: "center",
                }}
                onPress={() => {
                  this.setState({ addListModal: false });
                  this.addList();
                }}
              >
                ✔️
              </Text>
            </View>
          </View>
        </Modal>
      </ScrollView>
    );
  }
}
