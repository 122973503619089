import React from "react";
import { Dimensions, Modal, Text, Pressable, View } from "react-native";
import { getColorForCategory } from "../colors";

class Selector extends React.Component<
  {
    options: {
      name: string;
      id: string;
    }[];

    onChange: (value: { name: string; id: string } | null) => void;

    darkMode: boolean;

    addOption?: boolean;
    onAddOption?: () => void;

    renameOption?: boolean;
    onRenameOption?: (option: { name: string; id: string }) => void;

    noUnset?: boolean;

    default?: { name: string; id: string };
  },
  {
    isOpen: boolean;
    selected: {
      name: string;
      id: string;
    } | null;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      selected: props.default ?? null,
    };
  }

  render() {
    const { options, onChange } = this.props;
    const { isOpen, selected } = this.state;
    return (
      <View
        style={{
          borderRadius: 5,
          padding: 5,
          margin: 5,
          backgroundColor: this.props.darkMode ? "#333" : "#ccc",
        }}
      >
        <View>
          <Pressable onPress={() => this.setState({ isOpen: !isOpen })}>
            <Text
              style={{
                fontSize: 20,
                backgroundColor: getColorForCategory(selected?.name ?? "WÄHLEN")
                  .backgroundColor,
                color: getColorForCategory(selected?.name ?? "WÄHLEN").color,
                borderRadius: 5,
                padding: 5,
              }}
            >
              {selected?.name ?? "WÄHLEN"}
            </Text>
          </Pressable>
        </View>
        <Modal
          animationType="fade"
          transparent={true}
          visible={isOpen}
          onRequestClose={() => {
            this.setState({ isOpen: false });
          }}
          style={{
            backgroundColor: this.props.darkMode ? "#000" : "#fff",
          }}
        >
          <Pressable
            style={{
              position: "absolute",
              backgroundColor: this.props.darkMode ? "#000" : "#fff",
              opacity: 0.5,
              width: "100%",
              height: "100%",
            }}
            onPress={() => {
              this.setState({ isOpen: false });
            }}
          ></Pressable>
          <View
            style={{
              position: "absolute",
              backgroundColor: this.props.darkMode ? "#555" : "#aaa",
              borderRadius: 5,
              padding: 5,
              margin: 5,
              top: 100,
              left: Dimensions.get("window").width / 2 - 10,
              width: Dimensions.get("window").width / 2,
              height: "auto",
            }}
          >
            {!this.props.noUnset ? (
              <View
                style={{
                  backgroundColor:
                    getColorForCategory("WÄHLEN").backgroundColor,
                  borderRadius: 5,
                  padding: 5,
                  margin: 5,
                }}
              >
                <Pressable
                  onPress={() => {
                    this.setState({ isOpen: false, selected: null });
                    onChange(null);
                  }}
                  onLongPress={() => {
                    this.setState({ isOpen: false });
                    this.props.onAddOption?.();
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      textAlign: "center",
                      color: getColorForCategory("WÄHLEN").color,
                    }}
                  >
                    WÄHLEN
                  </Text>
                </Pressable>
              </View>
            ) : (
              <></>
            )}
            {options.map((option) => (
              <View
                key={option.id}
                style={{
                  backgroundColor: getColorForCategory(option.name)
                    .backgroundColor,
                  borderRadius: 5,
                  padding: 5,
                  margin: 5,
                }}
              >
                <Pressable
                  onPress={() => {
                    this.setState({ isOpen: false, selected: option });
                    onChange(option);
                  }}
                  onLongPress={() => {
                    this.setState({ isOpen: false });
                    this.props.renameOption &&
                      this.props.onRenameOption?.(option);
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      color: getColorForCategory(option.name).color,
                      textAlign: "center",
                    }}
                  >
                    {option.name}
                  </Text>
                </Pressable>
              </View>
            ))}
            {/* Add additional options */}
            {this.props.addOption && (
              <View
                style={{
                  backgroundColor: this.props.darkMode ? "#777" : "#888",
                  borderRadius: 5,
                  padding: 5,
                  margin: 5,
                }}
              >
                <Pressable
                  onPress={() => {
                    this.setState({ isOpen: false });
                    this.props.onAddOption?.();
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      color: this.props.darkMode ? "#fff" : "#000",
                      textAlign: "center",
                    }}
                  >
                    --- Neu ---
                  </Text>
                </Pressable>
              </View>
            )}
          </View>
        </Modal>
      </View>
    );
  }
}

export default Selector;
