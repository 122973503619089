import React from "react";
import type E from "../../types";
import { View, Text, TextInput, Pressable } from "react-native";

class EditItemOnListModal extends React.Component<
  {
    itemOnList: E.ItemOnList;
    item: E.Item;
    apiRoot: string;
    apiToken: string;
    close: (item: E.ItemOnList) => void;
    darkMode: boolean;
  },
  {
    amount: number;
    additional_info: string;
  }
> {
  constructor(props: {
    itemOnList: E.ItemOnList;
    item: E.Item;
    apiRoot: string;
    apiToken: string;
    close: (item: E.ItemOnList) => void;
    darkMode: boolean;
  }) {
    super(props);
    this.state = {
      amount: props.itemOnList.amount,
      additional_info: props.itemOnList.additional_info,
    };
  }

  editItemOnList() {
    fetch(
      `${this.props.apiRoot}/lists/${this.props.itemOnList.einkaufsliste_id}/items_on_list/${this.props.itemOnList.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.apiToken,
        },
        body: JSON.stringify({
          amount: this.state.amount,
          additional_info:
            this.state.additional_info === "" ? "" : this.state.additional_info,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.props.close(res);
      });
  }

  render() {
    return (
      <>
        <Pressable
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          onPress={() => {
            this.props.close({
              ...this.props.itemOnList,
              amount: this.state.amount,
              additional_info: this.state.additional_info,
            });
          }}
        ></Pressable>
        <View
          style={{
            backgroundColor: "#000",
            padding: 10,
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",

            position: "absolute",
            top: "10%",
            left: "10%",

            width: "80%",
            height: "30%",
          }}
        >
          <Text
            style={{
              color: "#fff",
              fontSize: 20,
            }}
          >
            {this.props.item.name}
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: "#fff",
              }}
            >
              Anzahl:{" "}
            </Text>
            <TextInput
              placeholder="Anzahl"
              keyboardType="numeric"
              defaultValue={this.state.amount.toString()}
              onChange={(e) => {
                if (
                  e.nativeEvent.text === "" ||
                  e.nativeEvent.text === "-" ||
                  e.nativeEvent.text === "+"
                )
                  return;
                this.setState({ amount: parseInt(e.nativeEvent.text) });
              }}
              style={{
                width: "50%",
                color: "#fff",
                fontSize: 20,

                textAlign: "center",

                padding: 5,
                margin: 5,
                borderRadius: 10,

                borderColor: "#fff",
                borderStyle: "solid",
                borderWidth: 1,
              }}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: "#fff",
              }}
            >
              Zusatzinfo:{" "}
            </Text>
            <TextInput
              placeholder="Zusatzinfo"
              defaultValue={this.state.additional_info}
              onChange={(e) => {
                this.setState({ additional_info: e.nativeEvent.text });
              }}
              style={{
                width: "50%",
                color: "#fff",
                fontSize: 20,

                padding: 5,
                margin: 5,
                borderRadius: 10,

                borderColor: "#fff",
                borderStyle: "solid",
                borderWidth: 1,
              }}
            />
          </View>
          <Pressable
            onPress={() => {
              this.editItemOnList();
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontSize: 20,

                backgroundColor: "#f00",
                borderRadius: 10,
                padding: 5,
              }}
            >
              Speichern
            </Text>
          </Pressable>
        </View>
      </>
    );
  }
}

export default EditItemOnListModal;
