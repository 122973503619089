import React from "react";
import type E from "../../types";
import { View, Text, TextInput, Pressable } from "react-native";

class AddCategoryModal extends React.Component<
  {
    apiRoot: string;
    apiToken: string;
    close: (item: E.Kategorie | null) => void;
    list: string;
    darkMode: boolean;
  },
  {
    name: string;
  }
> {
  constructor(props: {
    apiRoot: string;
    apiToken: string;
    close: (item: E.Kategorie | null) => void;
    list: string;
    darkMode: boolean;
  }) {
    super(props);
    this.state = {
      name: "",
    };
  }

  addCategory() {
    if (this.state.name === "") return;

    fetch(`${this.props.apiRoot}/lists/${this.props.list}/categories`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.apiToken,
      },
      body: JSON.stringify({
        name: this.state.name,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.props.close(res);
      });
  }

  render() {
    return (
      <>
        <Pressable
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          onPress={() => {
            this.props.close(null);
          }}
        ></Pressable>
        <View
          style={{
            backgroundColor: "#000",
            padding: 10,
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",

            position: "absolute",
            top: "10%",
            left: "10%",

            width: "80%",
            height: "30%",
          }}
        >
          <Text
            style={{
              color: "#fff",
              fontSize: 20,
            }}
          >
            Neue Kategorie
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: "#fff",
              }}
            >
              Name:{" "}
            </Text>
            <TextInput
              placeholder="Name"
              keyboardType="default"
              defaultValue={this.state.name}
              onChange={(e) => {
                this.setState({ name: e.nativeEvent.text });
              }}
              style={{
                width: "50%",
                color: "#fff",
                fontSize: 20,

                textAlign: "center",

                padding: 5,
                margin: 5,
                borderRadius: 10,

                borderColor: "#fff",
                borderStyle: "solid",
                borderWidth: 1,
              }}
            />
          </View>
          <Pressable
            onPress={() => {
              this.addCategory();
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontSize: 20,

                backgroundColor: "#f00",
                borderRadius: 10,
                padding: 5,
              }}
            >
              Speichern
            </Text>
          </Pressable>
        </View>
      </>
    );
  }
}

export default AddCategoryModal;
