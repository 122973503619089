import React from "react";
import type E from "../../types";
import { View, Text, TextInput, Pressable } from "react-native";

class RenameCategoryModal extends React.Component<
  {
    category: E.Kategorie;
    apiRoot: string;
    apiToken: string;
    close: (item: E.Kategorie | null | undefined) => void;
    darkMode: boolean;
  },
  {
    name: string;
    confirmDelete: boolean;
  }
> {
  constructor(props: {
    category: E.Kategorie;
    apiRoot: string;
    apiToken: string;
    close: (item: E.Kategorie | null | undefined) => void;
    darkMode: boolean;
  }) {
    super(props);
    this.state = {
      name: props.category.name,
      confirmDelete: false,
    };
  }

  renameCategory() {
    if (this.state.name === "") return;

    fetch(
      `${this.props.apiRoot}/lists/${this.props.category.einkaufsliste_id}/categories/${this.props.category.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.apiToken,
        },
        body: JSON.stringify({
          name: this.state.name,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.props.close(res);
      });
  }

  private deleteTimeout: NodeJS.Timeout | null = null;
  clickDeleteCategory() {
    if (this.state.confirmDelete) {
      this.deleteCategory();
    }
    this.setState({ confirmDelete: true });
    clearTimeout(this.deleteTimeout!);
    this.deleteTimeout = setTimeout(() => {
      this.setState({ confirmDelete: false });
    }, 3000);
  }

  deleteCategory() {
    fetch(
      `${this.props.apiRoot}/lists/${this.props.category.einkaufsliste_id}/categories/${this.props.category.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.apiToken,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.props.close(null);
      });
  }

  render() {
    return (
      <>
        <Pressable
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          onPress={() => {
            this.props.close(undefined);
          }}
        ></Pressable>
        <View
          style={{
            backgroundColor: "#000",
            padding: 10,
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",

            position: "absolute",
            top: "10%",
            left: "10%",

            width: "80%",
            height: "30%",
          }}
        >
          <Text
            style={{
              color: "#fff",
              fontSize: 20,
            }}
          >
            Katgeorie umbenennen
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: "#fff",
              }}
            >
              Name:{" "}
            </Text>
            <TextInput
              placeholder="Name"
              keyboardType="default"
              defaultValue={this.state.name}
              onChange={(e) => {
                this.setState({ name: e.nativeEvent.text });
              }}
              style={{
                width: "50%",
                color: "#fff",
                fontSize: 20,

                textAlign: "center",

                padding: 5,
                margin: 5,
                borderRadius: 10,

                borderColor: "#fff",
                borderStyle: "solid",
                borderWidth: 1,
              }}
            />
          </View>
          <Pressable
            onPress={() => {
              this.renameCategory();
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontSize: 20,

                backgroundColor: "#f00",
                borderRadius: 10,
                padding: 5,
              }}
            >
              Speichern
            </Text>
          </Pressable>
          <Pressable
            onPress={() => {
              this.clickDeleteCategory();
            }}
          >
            <Text
              style={{
                color: "#fff",
                textAlign: "center",
                fontSize: 20,

                backgroundColor: "#f00",
                borderRadius: 10,
                padding: 5,
              }}
            >
              {this.state.confirmDelete ? "✔️" : "🗑️"}
            </Text>
          </Pressable>
        </View>
      </>
    );
  }
}

export default RenameCategoryModal;
